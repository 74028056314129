import { Typography } from '@mui/material';
import React from 'react';
import Layout from './layout.js';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import withMeasurement from './ganalytics.js';

const Billing = () => {
    const enabled = false;
    const invoiceData = [
        { id: 1, key: 'Month', value: 'August' },
        { id: 2, key: 'User fixed cost', value: '$10' },
        { id: 4, key: 'Call Costs', value: '$30' },
        { id: 5, key: 'Total', value: '$280' },
    ];

    const columns = [
        { field: 'key', headerName: 'Key', flex: 1 },
        { field: 'value', headerName: 'Value', flex: 1 },
    ];
    return (
        <Layout path='/billing'>
            { enabled && <>
                <Typography variant="h1" gutterBottom sx={{
                    fontSize: '3rem',
                    fontWeight: '100',
                }}> 
                    Latest invoice 
                </Typography>
                <Box>
                    <DataGrid
                        rows={invoiceData}
                        columns={columns}
                        hideFooter
                        hideHeaderFilterMenu
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnSorting
                        disableColumnFilter
                    />
                </Box>
            </>
            }
            {
                !enabled && 
                    <Typography variant='h1' sx={{
                        fontSize: '2rem',
                    }}> 
                        We are working on it. You will see here your invoices and billing details.
                    </Typography>

            }
        </Layout>
    );
};

export default withMeasurement(Billing, 'billing');
