import React, { useState } from 'react';
import axios from 'axios';
import * as Twilio from '@twilio/voice-sdk';
import Layout from './layout.js';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import Countries from './countries.js';
import { Button, Typography } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';
import {CallOutlined} from '@mui/icons-material'
import {phone} from 'phone';
import withMeasurement from './ganalytics.js';

const Call = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState(Countries[0]);
    const [inputCountry, setInputCountry] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [callStatus, setCallStatus] = useState('');
    const [isCallOngoing, setIsCallOngoing] = useState(false);
    const [ongoingCall, setOngoingCall] = useState(null);
    const [callVolume, setCallVolume] = useState(0.0);

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        setValidPhoneNumber(currentNumber(country, e.target.value).isValid);
    }

    const handleCountryChange = (country) => {
        setCountry(country)
        setValidPhoneNumber(currentNumber(country, phoneNumber).isValid);
    }

    const currentNumber = (country, phoneNumber) => {
        if (!country) {
            return {isValid: false}
        }
        const number = '+' +  country.phone + phoneNumber;
        console.log(number);
        const res = phone(number);
        setValidPhoneNumber(res.isValid)
        return res
    }

    const handleCall = async () => {
        try {
            setIsCallOngoing(true);
            const numberToCall = currentNumber(country, phoneNumber); 
            console.log(numberToCall);
            if (!numberToCall.isValid) {
                setValidPhoneNumber(false);
                console.error(numberToCall);
                setIsCallOngoing(false);
                return
            }
            const response = await axios.post('https://api.calls.ostadent.com/token', {});
            const token = response.data.token;

            const twilioDevice = new Twilio.Device(token);
            const call = await twilioDevice.connect({params: { To: numberToCall.phoneNumber }});
            setOngoingCall(call);
            call.on('disconnect', () => {
                handleCallDisconnect();
            });
            call.on('error', (error) => {
                handleCallError(error);
            });
            call.on('accept', (call) => {
                handleCallAccpeted(call);
            });
            call.on('ringing', (hasEarlyMedia) => {
                handleRinging(hasEarlyMedia);
            })
            call.on('cancel', () => {
                handleCallCanceled();
            });
            call.on('reconnected', () => {
                handleCallReconnected();
            });
            call.on('reconnecting', (error) => {
                handleCallReconnecting(error);
            });
            call.on('reject', () => {
                handleCallRejected();
            });
            call.on('warning', (warning) => {
                handleCallWarning(warning);
            });
            call.on('volume', (inputVolume, outputVolume) => {
                handleVolumeUpdate(inputVolume, outputVolume);
            });

        } catch (error) {
            console.log('Error setting up Twilio Device:', error);
            setIsCallOngoing(false);
        }
    };

    const handleCallAccpeted = (call) => {
        console.log(call);
        setCallStatus("Ringing ...");
    }

    const handleCallDisconnect = () => {
        console.log("Call disconnected");
        setIsCallOngoing(false);
        setCallStatus("Call disconnected ...");
    }

    const handleCallError = (error) => {
        console.error(error);
        setIsCallOngoing(false);
        setCallStatus("Call error: " + error);
    }

    const handleCallReconnecting = (error) => {
        console.error(error);
        setCallStatus("Reconnecting ..." + error);
    }

    const handleCallReconnected = () => {
        console.log("Call reconnected");
        setCallStatus("Call reconnected ... ");
    }

    const handleCallRejected = () => {
        console.log("Call rejected");
        setIsCallOngoing(false);
        setCallStatus("Call rejected ...");
    }

    const handleCallCanceled = () => {
        console.log("Call canceled");
        setIsCallOngoing(false);
        setCallStatus("Call canceled ...");
    }

    const handleRinging = (hasEarlyMedia) => {
        console.log(hasEarlyMedia);
        console.log("Ringing");
        setCallStatus("Ringing ...");
    }

    const handleCallWarning = (warning) => {
        console.log(warning);
        setCallStatus("Call got a warning ..." + warning);
    }
    const endCall = () => {
        console.log("Ending call")
        setCallStatus("Ending call ...");
        if (ongoingCall === null) {
            console.error("error: disconnecting a closed call");
            return
        }
        ongoingCall.disconnect();
        setCallStatus("");
        setIsCallOngoing(false);
    }

    const handleVolumeUpdate = (inputVolume, outputVolume) => {
        setCallVolume(((inputVolume + outputVolume) / 2) * 100);
    }
    return (
        <Layout path='/call'>
            <Container>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={4}>
                        <Autocomplete
                            disabled={isCallOngoing}
                            id="country-select"
                            onChange={(event, newValue) => {
                                handleCountryChange(newValue);
                            }}
                            value={country}
                            inputValue={inputCountry}
                            onInputChange={(event, newInputValue) => {
                                setInputCountry(newInputValue);
                            }}
                            options={Countries}
                            autoHighlight
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                        key={key}
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...optionProps}
                                    >
                                        <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            alt=""
                                        />
                                        {option.label} ({option.code}) +{option.phone}
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a country"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField 
                            id="phoneNumber" 
                            label="Phone number" 
                            disabled={isCallOngoing}
                            variant="outlined" 
                            fullWidth
                            inputProps={{ 
                                inputMode: 'numeric', 
                                pattern: '[0-9]*',    // Allows only numbers
                                style: {
                                    appearance: 'textfield',
                                    MozAppearance: 'textfield',
                                    WebkitAppearance: 'none',
                                },
                            }}
                            value={phoneNumber}
                            color={!validPhoneNumber? 'warning': 'success'}
                            onChange={handlePhoneNumberChange}
                            type='number'
                        />
                    </Grid>
                </Grid>
            </Container>
            <Container 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh', // Adjust height as needed
                }}
            >
                <Grid container spacing={1} justifyContent="center"> 
                    <Grid item xs={12}>
                        <Typography
                            align="center"
                            gutterBottom
                            sx={{
                                fontSize: '3rem',
                                fontWeight: '100',
                                fontFamily: ''
                            }}
                        >
                            + {country? country.phone : "xx"} {phoneNumber === ""? "xxx-xxx-xxxx": phoneNumber } 
                        </Typography>
                    </Grid>
                    {
                        !validPhoneNumber && 
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                <Typography color='warning' sx={{ color: 'red' }}> Invalid phone number, please check the country code and phone number</Typography>
                            </Grid>
                    }
                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#FFF',
                        }}
                    >
                        <Button variant='contained' color='success' onClick={handleCall} disabled={isCallOngoing}> <CallOutlined/> Make call </Button> 
                    </Grid>
                    {
                            isCallOngoing &&                        
                            <Grid container 
                                sx={{
                                    display: 'flex',
                                    border: 'solid #F2F2F2',
                                    justifyContent: 'center',
                                    borderRadius: '15px',
                                    padding: '1rem',
                                    margin: '1rem',
                                }}
                            >
                                <Grid item xs={2}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '1.5rem',
                                    }}
                                >
                                    <Button variant='contained' color='error' onClick={endCall} disabled={!isCallOngoing}> <CallOutlined/> End call </Button> 
                                </Grid>
                                <Grid item xs={2}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Gauge width={100} height={100} value={callVolume} startAngle={-90} endAngle={90} />
                                </Grid>
                                <Grid item xs={6}
                                    sx={{
                                        display: 'flex',
                                        padding: '2rem',
                                    }}
                                >
                                    <Typography> {callStatus} </Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Container>
        </Layout>
    );
};

export default withMeasurement(Call, 'call');
