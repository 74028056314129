import React, { useState, useEffect } from 'react';
import { Fade, Box, Container, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LandingLayout from './landingLayout';
import withMeasurement from './ganalytics';

const Pricing = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <LandingLayout>
            <Fade in={loaded} timeout={1000}>
            <Box component="main" flex="1" py={4}
                sx={{
                    padding: '5vh',
                }}
            >
                <Container>
                    <Box
                    >
                        <Typography variant="h1" gutterBottom
                            sx={{
                                fontSize: isMobile ? '2rem' : '3.5rem',
                                fontWeight: '100',
                                textAlign: 'center',
                            }}
                        >
                            Flexible pricing model with no hidden costs
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                fontSize: isMobile ? '1rem':  '2rem',
                                fontWeight: '100',
                                padding: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            The pricing rules are simple. You pay a minimum charge of $8 per month. If your call costs exceeds the minimum charge, then you pay for what you called.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    > 
                        <CallCosts/>
                    </Box>
                    <Box
                        sx={{
                            padding: isMobile? '1rem':  '2rem',
                            textAlign: isMobile? 'center': 'left',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button 
                            variant= {isMobile? 'text': 'text'}
                            href='mailto:info@ostadent.com'
                        > 
                            Working in countries that are not listed above? {isMobile&& <br/>} We can make it work. Send us an email
                        </Button>
                    </Box>
                </Container>
            </Box>
            </Fade>
        </LandingLayout> 
    );
};

const CallCosts = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);


    const invoiceData = [
        { id: 1, key: 'USA', value: '$0.02' },
        { id: 2, key: 'Canada', value: '$0.02' },
        { id: 3, key: 'Germany', value: '$0.05' },
        { id: 4, key: 'Italy', value: '$0.05' },
        { id: 5, key: 'Ghana', value: '$0.38' },
    ];

    const columns = [
        { field: 'key', headerName: 'Country', flex: 1 },
        { field: 'value', headerName: 'Price per minute', flex: 1 },
    ];
    return (
        <Fade in={loaded} timeout={1500}>
        <DataGrid
            rows={invoiceData}
            columns={columns}
            hideFooter
            hideHeaderFilterMenu
            disableColumnSelector
            disableColumnMenu
            disableColumnSorting
            disableColumnFilter
        />
        </Fade>
    );

}

export default withMeasurement(Pricing, 'pricing');
