import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

ReactGA.initialize("G-0PV7ZNSJ5K");
const withMeasurement = (Child, pageName) => {
    return (props) => {
        useEffect(() => {
            ReactGA.send({ hitType: "pageview", page: pageName });
            ReactGA.gtag();
        }, );
        return <Child {...props} />;
    }
}

export default withMeasurement;
