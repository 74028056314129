import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Dialpad from '@mui/icons-material/Dialpad';
import Stack from '@mui/material/Stack';
import { Avatar, Button, IconButton, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';

const drawerWidth = 240;

export default function Layout({children, path}) {
    const username = window.localStorage.getItem("username");
    const pages = [
        {name:'About', path:'/about'}, 
        {name:'Pricing', path:'/pricing'}, 
    ];

    const sections = [
        {name:'Make a call', path:'/call'},
        {name:'My contacts', path:'/contacts'},
        {name:'Call history', path:'/history'},
        {name:'Billing', path:'/billing'},
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Box sx={{ my:'0px', px: '0px', display:'flex', flexGrow: 1, alignItems: 'center'}}> 
                        <Dialpad sx={{  display: { xs: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/call"
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            OSTACALLS

                        </Typography>
                        <Stack direction={'row'} sx={{mx: 3}}>
                            {pages.map((page) => (
                                <Button 
                                    key={page.name} 
                                    href={page.path}
                                    sx={{color:'white', display:'block'}}
                                >
                                    {page.name.toUpperCase()}
                                </Button>
                            ))}
                        </Stack>
                    </Box>
                    <Box>
                        <Tooltip title="Open settings">
                            <IconButton>
                                <Avatar sx={{ bgcolor: green[500] }}> {username[0].toUpperCase()} </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {sections.map((section, index) => (
                            <ListItem key={section.name} disablePadding>
                                <ListItemButton href={section.path} selected={path===section.path}>
                                    <ListItemIcon>
                                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={section.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}
