import React from 'react';
import Layout from './layout.js';
import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
export default function History() {
    const enabled = false;
    const rows = [
        { id: 1, country: 'Germany', time: new Date().toLocaleString(), duration: '3m20s', number: '+4901624770126' },
        { id: 2, country: 'Germany', time: new Date().toLocaleString(),  duration: '3m20s', number: '+4901624770126'   },
        { id: 3, country: 'Germany', time: new Date().toLocaleString(),  duration: '3m20s', number: '+4901624770126'   },
    ];

    const columns = [
        { field: 'time', headerName: 'Time', width: 150 },
        { field: 'country', headerName: 'Country', width: 150 },
        { field: 'duration', headerName: 'Duration', width: 150 },
        { field: 'number', headerName: 'Number', width: 150 },
        { field: 'cost', headerName: 'Cost', width: 150 },
    ];

    return (
        <Layout path='/history'>
            <div style={{ width: '100%' }}>
                { enabled && 
                <DataGrid rows={rows} columns={columns} />
                }
                {
                    !enabled && 
                        <Typography variant='h1' sx={{
                            fontSize: '2rem',
                        }}> 
                            We are working on it. You will be able to see your call history here.
                        </Typography>

                }
            </div>
        </Layout>
    );
}

