import React, { useState, useEffect } from 'react';
import { Fade, Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import LandingLayout from './landingLayout'
import withMeasurement from './ganalytics';

const About = () => {
    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <LandingLayout>
            <Fade in={loaded} timeout={1000}>
            <Box component="main" flex="1" py={4}
                sx={{
                    [theme.breakpoints.up('md')]: {
                        padding: '5vh',
                    }
                }}
            >
                <Container>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                minHeight: '70vh',
                                paddingLeft: '2rem',
                            },
                            [theme.breakpoints.down('md')]: {
                                paddingBottom: '2rem',
                            }
                        }}
                    >
                        <Typography variant="h1" gutterBottom
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '4rem',
                                    textAlign: 'left',        
                                },
                                fontSize: '2rem',
                                textAlign: 'center',
                                fontWeight: '100',
                            }}
                        >
                            Making phone calls easy and affordable 
                        </Typography>
                        <Typography variant="body1"
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '2rem',
                                    textAlign: 'left',
                                },
                                fontSize: '1.1rem',
                                textAlign: 'center',
                                fontWeight: '100',
                                padding: '1rem',
                            }}
                        >
                            Ostacalls is a provider of online telecommunication services, dedicated to helping export and import businesses stay connected with their partners and clients worldwide. Our platform allows you to make phone calls online using registered numbers, ensuring professionalism and reliability in all your communications.
                        </Typography>
                        <CallToAction/>
                    </Box>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                padding: '2rem',
                                minHeight: '70vh',
                            }
                        }}
                    >
                        <Typography variant="h1" gutterBottom
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '4rem',
                                    textAlign: 'left',
                                },
                                fontSize: '2rem',
                                textAlign: 'center',
                                fontWeight: '100',
                            }}
                        >
                            Our Product
                        </Typography>
                        <Typography variant="body1" paragraph
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '2rem',
                                    padding: '1rem',
                                    textAlign: 'left',
                                },
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                fontWeight: '100',
                            }}
                        >
                            Our platform offers a seamless solution for businesses looking to enhance their communication strategy. With Ostacalls, you can:
                        </Typography>
                        <ul
                            sx={{
                                fontSize: '2rem',
                                fontWeight: '100',
                                padding: '1rem',
                            }}
                        >
                            <li>
                                <Typography variant="body1"
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '2rem',
                                            padding: '1rem',
                                        },
                                        fontSize: '1rem',
                                        fontWeight: '100',
                                    }}

                                >Register and manage local or international phone numbers for your business.</Typography>
                            </li>
                            <li>
                                <Typography variant="body1"
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '2rem',
                                            padding: '1rem',
                                        },
                                        fontSize: '1rem',
                                        fontWeight: '100',
                                    }}

                                >Make and receive calls online through our secure and user-friendly interface.</Typography>
                            </li>
                            <li>
                                <Typography variant="body1"
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '2rem',
                                            padding: '1rem',
                                        },
                                        fontSize: '1rem',
                                        fontWeight: '100',
                                    }}

                                >Enjoy competitive pricing that helps you reduce communication costs while maintaining high-quality service.</Typography>
                            </li>
                            <li>
                                <Typography variant="body1"
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '2rem',
                                            padding: '1rem',
                                        },
                                        fontSize: '1rem',
                                        fontWeight: '100',
                                    }}
                                >Monitor your costs and go through your call history. No hidden costs.</Typography>
                            </li>
                            <li>
                                <Typography variant="body1"
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '2rem',
                                            padding: '1rem',
                                        },
                                        fontSize: '1rem',
                                        fontWeight: '100',
                                    }}
                                >Store and manage your business contacts.</Typography>
                            </li>
                        </ul>
                    </Box>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                minHeight: '70vh',
                                padding: '2rem',
                            },
                            [theme.breakpoints.down('md')]: {
                                padding: '0.4rem',
                                marginTop: '2rem',
                            }
                        }}
                    >
                        <Typography variant="h4" gutterBottom
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '4rem',
                                    textAlign: 'left',
                                },
                                fontSize: '2rem',
                                fontWeight: '100',
                                textAlign: 'center',
                            }}

                        >
                            How We Can Help You
                        </Typography>
                        <Typography variant="body1" paragraph
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '2rem',
                                    padding: '1rem',
                                },
                                fontSize: '1rem',
                                fontWeight: '100',
                                padding: '0.5rem',
                                marginLeft: '1rem',
                            }}

                        >
                            Ostacalls is designed to meet the needs of businesses engaged in international trade. Whether you're an exporter, importer, or a logistics provider, our service ensures that you stay connected with your global network, reducing the complexities and costs associated with international communication.                         </Typography>
                        <Typography variant="body1" paragraph
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '2rem',
                                    padding: '1rem',
                                },
                                fontSize: '1rem',
                                fontWeight: '100',
                                padding: '0.5rem',
                                marginLeft: '1rem',
                            }}


                        >
                            Our competitive pricing allows you to allocate more resources to your core business while maintaining efficient and reliable communication with your partners worldwide.
                        </Typography>

                    </Box>
                </Container>
            </Box>
            </Fade>
        </LandingLayout>
    );
};

const CallToAction = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // Returns true for screens md and larger
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);
    return (
        <Fade in={loaded} timeout={2000}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.up('md')]: {
                        padding: '2rem',
                    }
                }}
            >
                {
                    isLargeScreen &&
                        <Button 
                            variant='outlined'
                            // size='small'
                            size={theme.breakpoints.up('md') ? 'large': 'small'}
                            sx={{
                                margin: '1rem',
                                [theme.breakpoints.up('md')]: {
                                    padding: '1rem'
                                }
                            }}
                            href='mailto:info@ostadent.com'
                        > Send us an email </Button>
                }
                <Button 
                    variant='contained'
                    // size='small'
                    size={theme.breakpoints.up('md') ? 'large': 'small'}
                    sx={{
                        margin: '1rem',
                        [theme.breakpoints.up('md')]: {
                            padding: '1rem'
                        }
                    }}
                    href='/pricing'
                > View our prices </Button>
            </Box>
        </Fade>
    )
}

export default withMeasurement(About, 'about');

