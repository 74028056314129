import React from 'react';
import Layout from './layout.js';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import {CallOutlined} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
import withMeasurement from './ganalytics.js';

const Contacts = () => {
    const enabled = false;
    const handleDelete = (id) => {
        console.log(`Delete row with id: ${id}`);
    };

    const handleOpen = (id) => {
        console.log(`Open row with id: ${id}`);
    };
    const rows = [
        {
            id: 1,
            name: 'Ahmed Abouzied',
            country: 'Germany',
            number: '+4901624770126',
        },
        {
            id: 2,
            name: 'Ahmed Abouzied',
            country: 'Germany',
            number: '+4901624770126',
        },
        {
            id: 3,
            name: 'Ahmed Abouzied',
            country: 'Germany',
            number: '+4901624770126',
        }
    ];

    const columns = [
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'country', headerName: 'Country'},
        { field: 'number', headerName: 'Number', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 350,
            sortable: false,
            renderCell: (params) => (
                <>

                    <Button
                        startIcon={<CallOutlined/>}
                        onClick={() => handleOpen(params.row.id)}
                    >
                        Call
                    </Button>
                    <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Layout path='/contacts'>
            <div style={{ width: '100%' }}>
                {
                    enabled &&
                        <DataGrid rows={rows} columns={columns} />
                }
                {
                    !enabled && 
                        <Typography variant='h1' sx={{
                            fontSize: '2rem',
                        }}> 
                            We are working on it. You will be able to add your contacts and call them directly.
                        </Typography>
                }
            </div>
        </Layout>
    );
}

export default withMeasurement(Contacts, 'contacts');
