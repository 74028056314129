import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './login';
import Logout from './logout';
import Call from './call';
import Contacts from './contacts';
import History from './history';
import Billing from './billing';
import About from './about';
import Pricing from './pricing';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<About/>} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/call" element={<PrivateRoute><Call/></PrivateRoute>} />
        <Route path="/contacts" element={<PrivateRoute><Contacts/></PrivateRoute>} />
        <Route path="/history" element={<PrivateRoute><History/></PrivateRoute>} />
        <Route path="/billing" element={<PrivateRoute><Billing/></PrivateRoute>} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/about" element={<About/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

const PrivateRoute = ({ children }) => {
  return localStorage.getItem('jwtToken') ? children : <Navigate to="/login" />;
};

export default App;
